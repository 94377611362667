export const ALL_BRAND_REQUEST = "ALL_BRAND_REQUEST";
export const ALL_BRAND_SUCCESS = "ALL_BRAND_SUCCESS";
export const ALL_BRAND_FAIL = "ALL_BRAND_FAIL";

export const BRAND_DETAILS_REQUEST = "BRAND_DETAILS_REQUEST";
export const BRAND_DETAILS_SUCCESS = "BRAND_DETAILS_SUCCESS";
export const BRAND_DETAILS_FAIL = "BRAND_DETAILS_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";

export const ADMIN_BRAND_REQUEST = "ADMIN_BRAND_REQUEST";
export const ADMIN_BRAND_SUCCESS = "ADMIN_BRAND_SUCCESS";
export const ADMIN_BRAND_FAIL = "ADMIN_BRAND_FAIL";

export const NEW_BRAND_REQUEST = "NEW_BRAND_REQUEST";
export const NEW_BRAND_SUCCESS = "NEW_BRAND_SUCCESS";
export const NEW_BRAND_RESET = "NEW_BRAND_RESET";
export const NEW_BRAND_FAIL = "NEW_BRAND_FAIL";

export const UPDATE_BRAND_REQUEST = "UPDATE_BRAND_REQUEST";
export const UPDATE_BRAND_SUCCESS = "UPDATE_BRAND_SUCCESS";
export const UPDATE_BRAND_RESET = "UPDATE_BRAND_RESET";
export const UPDATE_BRAND_FAIL = "UPDATE_BRAND_FAIL";

export const DELETE_BRAND_REQUEST = "DELETE_BRAND_REQUEST";
export const DELETE_BRAND_SUCCESS = "DELETE_BRAND_SUCCESS";
export const DELETE_BRAND_RESET = "DELETE_BRAND_RESET";
export const DELETE_BRAND_FAIL = "DELETE_BRAND_FAIL";

export const NEW_REVIEW_REQUEST = "NEW_REVIEW_REQUEST";
export const NEW_REVIEW_SUCCESS = "NEW_REVIEW_SUCCESS";
export const NEW_REVIEW_RESET = "NEW_REVIEW_RESET";
export const NEW_REVIEW_FAIL = "NEW_REVIEW_FAIL";

export const ALL_REVIEW_REQUEST = "ALL_REVIEW_REQUEST";
export const ALL_REVIEW_SUCCESS = "ALL_REVIEW_SUCCESS";
export const ALL_REVIEW_FAIL = "ALL_REVIEW_FAIL";

export const DELETE_REVIEW_REQUEST = "DELETE_REVIEW_REQUEST";
export const DELETE_REVIEW_SUCCESS = "DELETE_REVIEW_SUCCESS";
export const DELETE_REVIEW_RESET = "DELETE_REVIEW_RESET";
export const DELETE_REVIEW_FAIL = "DELETE_REVIEW_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
