export const ALL_SLIDER_REQUEST = "ALL_SLIDER_REQUEST";
export const ALL_SLIDER_SUCCESS = "ALL_SLIDER_SUCCESS";
export const ALL_SLIDER_FAIL = "ALL_SLIDER_FAIL";

export const SLIDER_DETAILS_REQUEST = "SLIDER_DETAILS_REQUEST";
export const SLIDER_DETAILS_SUCCESS = "SLIDER_DETAILS_SUCCESS";
export const SLIDER_DETAILS_FAIL = "SLIDER_DETAILS_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";

export const ADMIN_SLIDER_REQUEST = "ADMIN_SLIDER_REQUEST";
export const ADMIN_SLIDER_SUCCESS = "ADMIN_SLIDER_SUCCESS";
export const ADMIN_SLIDER_FAIL = "ADMIN_SLIDER_FAIL";

export const NEW_SLIDER_REQUEST = "NEW_SLIDER_REQUEST";
export const NEW_SLIDER_SUCCESS = "NEW_SLIDER_SUCCESS";
export const NEW_SLIDER_RESET = "NEW_SLIDER_RESET";
export const NEW_SLIDER_FAIL = "NEW_SLIDER_FAIL";

export const UPDATE_SLIDER_REQUEST = "UPDATE_SLIDER_REQUEST";
export const UPDATE_SLIDER_SUCCESS = "UPDATE_SLIDER_SUCCESS";
export const UPDATE_SLIDER_RESET = "UPDATE_SLIDER_RESET";
export const UPDATE_SLIDER_FAIL = "UPDATE_SLIDER_FAIL";

export const DELETE_SLIDER_REQUEST = "DELETE_SLIDER_REQUEST";
export const DELETE_SLIDER_SUCCESS = "DELETE_SLIDER_SUCCESS";
export const DELETE_SLIDER_RESET = "DELETE_SLIDER_RESET";
export const DELETE_SLIDER_FAIL = "DELETE_SLIDER_FAIL";

export const NEW_REVIEW_REQUEST = "NEW_REVIEW_REQUEST";
export const NEW_REVIEW_SUCCESS = "NEW_REVIEW_SUCCESS";
export const NEW_REVIEW_RESET = "NEW_REVIEW_RESET";
export const NEW_REVIEW_FAIL = "NEW_REVIEW_FAIL";

export const ALL_REVIEW_REQUEST = "ALL_REVIEW_REQUEST";
export const ALL_REVIEW_SUCCESS = "ALL_REVIEW_SUCCESS";
export const ALL_REVIEW_FAIL = "ALL_REVIEW_FAIL";

export const DELETE_REVIEW_REQUEST = "DELETE_REVIEW_REQUEST";
export const DELETE_REVIEW_SUCCESS = "DELETE_REVIEW_SUCCESS";
export const DELETE_REVIEW_RESET = "DELETE_REVIEW_RESET";
export const DELETE_REVIEW_FAIL = "DELETE_REVIEW_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
